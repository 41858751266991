<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #scoreLevel="{ text }">
          <span class="score-level">{{ scoreLevel[text] }}</span>
        </template>
      </grid>
    </div>
    <a-modal
      title="自定义分发"
      :visible="visible"
      :footer="null"
      centered
      width="880px"
      @cancel="handleCancel"
    >
      <!--    visible = false   -->
      <div>
        <search-form :items="itemsModal" @fnSearch="handleFnSearchModal" />

        <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
          <a-row>
            <a-col :span="8">
              <a-form-item
                label="过期时间"
                v-bind="validateInfos.expirationHour"
                required
              >
                <a-input-number
                  v-model:value="form.expirationHour"
                  :min="1"
                  @blur="handleBlurDtae"
                  :max="99"
                />
                <span class="tiem">小时</span>
              </a-form-item>
            </a-col>
            <!--            <a-col :span="8">-->
            <!--              <a-form-item label="提醒频率" v-bind="validateInfos.remind" required>-->
            <!--                <a-input-number v-model:value="form.remind" :min="0" @blur="handleBlurRemind"/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :span="8">
              <a-form-item
                label="回收方式"
                v-bind="validateInfos.recoveryMode"
                required
              >
                <a-select
                  class="selectInput"
                  v-model:value="form.recoveryMode"
                  @change="handleFnSearch"
                >
                  <a-select-option
                    v-for="item in reclaimList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <grid
          ref="gridRefModal"
          :allow-selection="{ type: 'radio' }"
          :columns="columnsModal"
          :code="$route.path"
          :url="urlModal"
          :search="searchModal"
          :url-params="{ marketingType: 2 }"
          :btn-operation="null"
          :scroll="{ x: 800, y: gridHeightModal }"
        >
        </grid>
        <a-row type="flex" justify="space-between" align="middle" class="mt-24">
          <a-col>您已经选择了{{ length }}条商机，请分发给指定营销人员</a-col>
          <a-col>
            <a-space>
              <a-button @click="visible = false">取消</a-button>
              <a-button type="primary" @click="handleFnBatch">分发</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { createVNode, reactive, ref, toRefs } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
import dictionaryApi from '@/api/dictionary.js';
import {
  message,
  Modal,
  Row,
  Col,
  Button,
  Space,
  Select,
  Form,
  InputNumber,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    Grid,
    Operation,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpace: Space,
    // ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const gridRefModal = ref();
    const state = reactive({
      search: {},
      searchModal: {},
      TagList: [],
      visible: false,
      length: 0,
      gridSelectedRowKeys: [],
      reclaimList: [
        { label: '手动回收', value: '0' },
        { label: '自动回收', value: '1' },
      ],
    });
    const form = reactive({
      recoveryMode: '1',
      expirationHour: '48',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      recoveryMode: [required],
      expirationHour: [required],
      // remind: [required],
      // channelId: [{ max: 32, message: '流名称长度不能超过32个字符' }],
    });
    // const range = (start, end) => {
    //   const result = [];
    //   for (let i = start; i < end; i++) {
    //     result.push(i);
    //   }
    //   return result;
    // };
    return {
      gridRef,
      gridRefModal,
      validateInfos,
      ...toRefs(state),
      form,
      scoreLevel: { high: '高分', middle: '中分', low: '低分' },
      items: [
        { key: 'contactName', label: '商机姓名' },
        { key: 'mobileNum', label: '手机' },
        { key: 'company', label: '公司' },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          showSearch: true,
          url: dictionaryApi.cityUrl,
        },
        {
          key: 'promoteUserId',
          label: '绑定人',
          type: 'select',
          valueKey: 'id',
          labelKey: 'userName',
          filterOption: 'label',
          showSearch: true,
          url: distributionApi.marketingUserListUrl,
        },
        {
          key: 'leadPoolId',
          label: '线索池',
          type: 'select',
          valueKey: 'leadPoolId',
          labelKey: 'leadPoolName',
          filterOption: 'label',
          showSearch: true,
          url: distributionApi.clueUrl,
        },
        {
          key: 'scoreLevel',
          label: '所属分阶',
          type: 'select',
          dataset: [
            { label: '全部', value: '' },
            { label: '高分', value: 'high' },
            { label: '中分', value: 'middle' },
          ],
        },
        // {
        //   key: 'industryLv1Code',
        //   label: '行业',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   showSearch: true,
        //   url: dictionaryApi.industryLv1CodeUrl,
        // },
        { key: 'industryLv1Code', label: '行业' },
      ],
      itemsModal: [
        { key: 'userName', label: '姓名' },
        { key: 'mobileNum', label: '手机' },
      ],
      columns: [
        {
          dataIndex: 'contactName',
          title: '商机姓名',
          width: 100,
          ellipsis: true,
        },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'industryLv1Code',
          title: '行业',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'leadPoolName',
          title: '线索池',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'scoreLevel',
          title: '所属分阶',
          slots: { customRender: 'scoreLevel' },
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'promoteUserName', title: '商机绑定人', width: 120 },
      ],
      columnsModal: [
        { dataIndex: 'userName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'companyName', title: '公司', ellipsis: true },
      ],
      btnOperation: [
        {
          type: 'batch',
          label: '批量分发',
          btnType: 'primary',
          permission: 'distribution:batch',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            const getTime = new Date().getTime();
            if (length) {
              Modal.confirm({
                title: `是否将已勾选的商机分发给绑定人？`,
                icon: createVNode(icons['InfoCircleOutlined']),
                okType: 'primary',
                centered: true,
                onOk: () => {
                  distributionApi
                    .batchDistribute('', {
                      ids: selectedRowKeys,
                      beginTime: getTime,
                      expirationHour: 48,
                      recoveryMode: 1,
                    })
                    .then(res => {
                      if (res) {
                        const { invalid, total, valid } = res;
                        Modal.info({
                          title: `本次分发商机${total}条，其中有效分发${valid}条，去重${invalid}条`,
                          centered: true,
                        });
                        gridRef.value.refreshGrid();
                      }
                    });
                },
              });
            } else {
              message.info('请先选择数据');
            }
          },
        },
        {
          type: 'custom',
          label: '自定义分发',
          btnType: '',
          permission: 'distribution:custom',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            if (length) {
              state.length = length;
              state.gridSelectedRowKeys = selectedRowKeys;
              state.visible = true;
            } else {
              message.info('请先选择数据');
            }
          },
        },
        {
          type: 'batch',
          label: '批量不分发',
          btnType: '',
          permission: 'distribution:batch',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            if (length) {
              Modal.confirm({
                title: `是否将已勾选的商机移到不分发池子中？`,
                icon: createVNode(icons['InfoCircleOutlined']),
                okType: 'primary',
                centered: true,
                onOk: () => {
                  distributionApi
                    .batchNoDistribute('', selectedRowKeys)
                    .then(res => {
                      if (res) {
                        message.success('操作成功');
                        gridRef.value.refreshGrid();
                      }
                    });
                },
              });
            } else {
              message.info('请先选择数据');
            }
          },
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'distribution:update',
          fnClick: () => {},
        },
        {
          type: 'delete',
          permission: 'distribution:delete',
          fnClick: () => {},
        },
      ],
      url: distributionApi.pageUrl,
      urlModal: distributionApi.marketingUserPageUrl,
      gridHeight: document.body.clientHeight - 457,
      gridHeightModal: document.body.clientHeight - 416,
      handleBlurDtae: () => {
        let num = form.expirationHour;
        if (num % 1 !== 0) {
          form.expirationHour = '';
          return message.error('请输入整正数');
        }
      },
      // handleBlurRemind: () => {
      //   let num = form.remind;
      //   if (num % 1 !== 0) {
      //     form.remind = '';
      //     return message.error('请输入整正数');
      //   }
      // },
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        state.search = temp;
      },
      handleFnSearchModal: value => {
        const temp = Object.assign({}, value);
        state.searchModal = temp;
      },
      handleCancel: () => {
        state.visible = false;
        resetFields();
      },
      handleFnBatch: () => {
        validate().then(() => {
          console.log(gridRefModal.value.selectedRows);
          const length = gridRefModal.value.selectedRows.length;
          const getTime = `${new Date().getTime()}`;
          if (length) {
            distributionApi
              .customDistribute('', {
                entry: 1,
                ids: state.gridSelectedRowKeys,
                promoteUserId: gridRefModal.value.selectedRows[0].id,
                ...form,
                beginTime: getTime,
              })
              .then(res => {
                if (res) {
                  state.visible = false;
                  const { invalid, total, valid } = res;
                  Modal.info({
                    title: `本次分发商机${total}条，其中有效分发${valid}条，去重${invalid}条`,
                    centered: true,
                  });
                  gridRef.value.refreshGrid();
                }
              });
          } else {
            message.info('请先选择推广人员');
          }
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main-grid {
//  max-height: calc(100vh - 161px);
//  overflow-y: auto;
//}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
.tiem {
  margin-left: 5px;
}
</style>
